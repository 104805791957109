
import { defineComponent } from "vue"

export default defineComponent({
	name: "layout-login",
	data() {
		return {
			links: {
				about: "https://corp.vacan.com/",
				terms: "https://corp.vacan.com/guide",
			},
			reference: {
				logo: "@/assets/images/logo.png",
				title: "ログイン",
				pageHeight: 375,
			},
		}
	},

	computed: {
		identifyCurrentDisplayTitle() {
			let result = ""
			switch (this.$route.name) {
				case "login-forgot-password":
				case "login-index":
					result = "ログイン"
					break
				case "login-reset-password":
					result = "新しいパスワードを登録"
					break
			}
			return result
		},
	},
})

import { defineStore } from "pinia"

// ユーザーストアインターフェース
export interface UserStoreType {
	// ユーザーID
	user_id: number | null
	// ユーザー名
	user_name: string | null
	// ユーザー名カナ
	user_name_kana: string | null
	// メールアドレス
	email: string | null
	// ユーザー権限ID
	role_id: number | null
	// ユーザー権限名
	role_name: string | null
	// 支社名
	affiliation: string | null
	// 支社ID複数(カンマ区切り)
	affiliation_ids: string | null
}

/**
 * ユーザーストア
 */
export const UserStore = defineStore("user", {
	state: () =>
		({
			user_id: null,
			user_name: "",
			user_name_kana: "",
			email: "",
			role_id: null,
			role_name: null,
			affiliation: null,
			affiliation_ids: null,
		} as UserStoreType),
	actions: {
		reset() {
			this.user_id = null
			this.user_name = ""
			this.user_name_kana = ""
			this.email = ""
			this.role_id = null
			this.role_name = null
			this.affiliation = null
			this.affiliation_ids = null
		},
	},
})


import { defineComponent } from "vue"

interface DisplayTitle {
	title: string
	description: string
}

export default defineComponent({
	name: "ContentsLayout.vue",
	computed: {
		displayTitle(): DisplayTitle {
			switch (this.$route.name) {
				// 支社
				case "branch-index":
					return {
						title: "支社設定",
						description: "支社の新規登録と編集ができます",
					}
				case "branch-form":
					return {
						title: "支社設定",
						description: "支社を登録します",
					}
				case "branch-edit":
					return {
						title: "支社設定",
						description: "支社の名称変更します",
					}
				// 支社
				case "district-index":
					return {
						title: "管区設定",
						description: "管区の新規登録と編集ができます",
					}
				case "district-form":
					return {
						title: "管区設定",
						description: "管区を登録します",
					}
				case "district-edit":
					return {
						title: "管区設定",
						description: "管区を編集します",
					}
				// 支社
				case "line-index":
					return {
						title: "線区設定",
						description: "線区の新規登録と編集ができます",
					}
				case "line-form":
					return {
						title: "線区設定",
						description: "線区を登録します",
					}
				case "line-edit":
					return {
						title: "線区設定",
						description: "線区の名称変更します",
					}
				// 支社
				case "sales-index":
					return {
						title: "営業所設定",
						description: "営業所の新規登録と編集ができます",
					}
				case "sales-form":
					return {
						title: "営業所設定",
						description: "営業所を登録します",
					}
				case "sales-edit":
					return {
						title: "営業所設定",
						description: "営業所の名称変更します",
					}
				// 駅
				case "station-index":
					return {
						title: "駅設定",
						description: "駅の新規登録と編集ができます",
					}
				case "station-form":
					return {
						title: "駅設定",
						description: "駅を登録する",
					}
				case "station-edit":
					return {
						title: "駅設定",
						description: "駅の名称変更します",
					}
				// トイレ
				case "toilet-index":
					return {
						title: "トイレ登録・編集",
						description: "トイレの登録・編集ができます",
					}
				case "toilet-form":
					return {
						title: "トイレ登録・編集",
						description: "項目を入力してください",
					}
				case "toilet-edit":
					return {
						title: "トイレ登録・編集",
						description:
							"登録情報を編集できます（※トイレIDと所属駅は変更できません）",
					}
				// トイレマップ
				case "toilet-map-index":
					return {
						title: "トイレマップ登録・編集",
						description: "トイレマップの登録・編集ができます",
					}
				case "toilet-map-form":
				case "toilet-edit-form":
					return {
						title: "対象トイレ設定",
						description: "マップを登録する駅を選択してください",
					}
				case "toilet-map-edit":
					return {
						title: "対象トイレ設定",
						description: "マップを登録する駅を選択してください",
					}
				case "toilet-map-view":
					return {
						title: "マップ編集",
						description:
							"個室・手洗い・小便器のアイコンをマップの上にドラッグして配置できます。",
					}
				// ユーザー
				case "users-index":
					return {
						title: "ユーザー管理",
						description: "ユーザーの検索・設定ができます",
					}
				case "users-form":
					return {
						title: "ユーザー管理",
						description: "新規ユーザーを登録します",
					}
				case "users-edit":
					return {
						title: "ユーザー管理",
						description: "ユーザー情報を編集できます",
					}
				// analysis
				case "analysis-index":
					return {
						title: "分析データダウンロード",
						description: "各種データのCSVがダウンロードできます",
					}
				// 管理ログ
				case "adminlog-index":
					return {
						title: "管理ログ",
						description: "",
					}
				// 管理ログ
				case "alertlog-index":
					return {
						title: "アラートログ",
						description: "",
					}
			}
			return {
				title: "-",
				description: "-",
			}
		},
	},
})

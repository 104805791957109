import { RouteRecordRaw } from "vue-router"
import LoginLayout from "@/layouts/LoginLayout.vue"
import http from "@/utils/http"

export default [
	{
		path: "/login",
		component: LoginLayout,
		children: [
			{
				path: "",
				name: "login-index",
				component: () => import("@/pages/login/index.vue"),
			},
			{
				path: "forgot",
				name: "login-forgot-password",
				component: () => import("@/pages/login/forgot.vue"),
			},
			{
				path: "reset",
				name: "login-reset-password",
				props: true,
				component: () => import("@/pages/login/reset.vue"),
				/* TODO fixed warning for unused function */
				beforeEnter(to, from, next): void {
					/* Validate URL if Expired */
					const token = to.query.token
					http
						.get(`verify-reset-password?token=${token}`)
						.then((response) => {
							to.params.context = response.data.user
							next()
						})
						.catch((error) => {
							console.log(error)
							next({ path: "notFound" })
						})
				},
			},
		],
	},
] as Array<RouteRecordRaw>

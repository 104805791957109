import { createApp } from "vue"
import { createPinia } from "pinia"
import App from "./App.vue"
import router from "./router"
import defaultMixin from "./mixins/default"
import roleMixin from "./mixins/role"
import http from "@/utils/http"
import { AxiosInstance } from "axios"
import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"
import { Form, Field } from "vee-validate"
import { NotificationStore } from "@/store/notification"
import { UserStore } from "./store/user"
import * as DateFilter from "./utils/filter/date-filter"
import { IndexStore } from "./store"

const pinia = createPinia()

// create vue app
const app = createApp(App)
app.use(pinia)
app.use(router)
app.mixin(defaultMixin)
app.mixin(roleMixin)
app.mount("#app")

// components
// // vee
app.component("veeForm", Form)
app.component("veeField", Field)

// globalProperties
// // http
app.config.globalProperties.$http = http

// // filters
const filters = {
	...DateFilter,
}
app.config.globalProperties.$filter = filters

// // store
const indexStore = IndexStore()
app.config.globalProperties.$store = indexStore

const notificationStore = NotificationStore()
app.config.globalProperties.$notification = notificationStore

const userStore = UserStore()
app.config.globalProperties.$currentUser = userStore

// sentry
// @see https://docs.sentry.io/platforms/javascript/guides/vue/#vue-3
Sentry.init({
	app,
	dsn: process.env.SENTRY_DSN,
	integrations: [
		new BrowserTracing({
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			tracingOrigins: [
				"https://admin.cleaning-cooperative.development.vacanservice.com/",
				"https://admin.cleaning-cooperative.staging.vacanservice.com/",
				"https://admin.cleaning-cooperative.vacanservice.com/",
			],
		}),
	],
	tracesSampleRate: 1.0,
})

// ts
declare module "@vue/runtime-core" {
	interface ComponentCustomProperties {
		$http: AxiosInstance
		$filter: typeof filters
		$store: typeof indexStore
		$notification: typeof notificationStore
		$currentUser: typeof userStore

		// default mixin
		eventTargetValue(event: Event): string
		// role mixins
		mixin_role_includes(ids: number[]): boolean
		mixin_role_unincludes(ids: number[]): boolean
	}
}

import axios, { AxiosInstance, AxiosRequestConfig } from "axios"
import cookies from "@/utils/cookies"
import { NotificationStore } from "@/store/notification"

// createPinia後にのみstore()を呼び出せるため、生成タイミングをずらす
let $notification

const instance: AxiosInstance = axios.create({
	baseURL: process.env.VUE_APP_HOST,
	timeout: 0,
	headers: {
		"Content-type": "application/json; charset=UTF-8",
		"Cache-Control": "no-cache, no-store",
		Pragma: "no-cache",
	},
})

// // Add a request interceptor
instance.interceptors.request.use(
	function (config: AxiosRequestConfig) {
		/* Check weather the Token is still valid */

		if (config.headers)
			config.headers.Authorization = `Bearer ${cookies.get("vn_access_token")}`

		return config
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error)
	}
)

// Add a response interceptor
instance.interceptors.response.use(
	function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		return response
	},
	function (error) {
		if (!$notification) {
			$notification = NotificationStore()
		}

		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		switch (error.request.status) {
			case 401:
				// Handle 401
				cookies.del("vn_access_token")
				cookies.del("vn_refresh_token")

				if (window.location.pathname != "/login") {
					window.location.href = "/"
				}

				break
			case 500:
				$notification.display = true
				$notification.type = "error"
				$notification.message =
					error.response.data.error ||
					"システムエラーが発生しました。しばらくしてから再度実行してください。"
				break
			default:
				console.log(`No Feedback Errors for error ${error.request.status}`)
				break
		}
		return Promise.reject(error)
	}
)
export default instance

import dayjs from "dayjs"
import "dayjs/locale/ja"

dayjs.locale("ja")

/**
 * dayjsインスタンスに変換する
 * @param {Date | number | string} value 日付情報
 * @returns 日付のdayjsインスタンス
 */
function toDayjs(value: Date | number | string) {
	return typeof value === "number" ? dayjs.unix(value) : dayjs(value)
}

/**
 * unixtimeを日付に変換する
 * @param {number} value unixtime
 * @returns {string} 日付文字列
 */
export function formatDate(value) {
	if (!value) return value
	return toDayjs(value).format("YYYY/MM/DD")
}

/**
 * unixtimeを時間に変換する
 * @param {number} value unixtime
 * @returns {string} 日付文字列
 */
export function formatTime(value) {
	if (!value) return value
	return toDayjs(value).format("HH:mm")
}

/**
 * unixtimeを日付時間に変換する
 * @param {number} value unixtime
 * @returns {string} 日付文字列
 */
export function formatDateTime(value) {
	if (!value) return value
	return toDayjs(value).format("YYYY/MM/DD HH:mm")
}

/**
 * unixtimeを日付時間(秒)に変換する
 * @param {number} value unixtime
 * @returns {string} 日付文字列
 */
export function formatDateTimeSec(value) {
	if (!value) return value
	return toDayjs(value).format("YYYY/MM/DD HH:mm:ss")
}

import { RouteRecordRaw } from "vue-router"

/**
 * UI component
 * test page for developer
 */
export default [
	{
		path: "/storybook",
		component: () => import("@/layouts/StorybookLayout.vue"),
		children: [
			{
				path: "",
				component: () => import("@/views/storybook/index.vue"),
			},
			{
				path: "components/button",
				component: () => import("@/views/storybook/components/ButtonBook.vue"),
			},
			{
				path: "components/datepicker",
				component: () =>
					import("@/views/storybook/components/DatepickerBook.vue"),
			},
			{
				path: "components/dialog",
				component: () => import("@/views/storybook/components/DialogBook.vue"),
			},
			{
				path: "components/input",
				component: () => import("@/views/storybook/components/InputBook.vue"),
			},
			{
				path: "components/notification",
				component: () =>
					import("@/views/storybook/components/NotificationBook.vue"),
			},
		],
		beforeEnter: (to, from, next) => {
			if (process.env.NODE_ENV === "production") {
				next({ path: "/" })
			} else {
				next()
			}
		},
	},
] as Array<RouteRecordRaw>

import { defineComponent } from "vue"

/**
 * 権限のmixins
 */
export default defineComponent({
	methods: {
		/**
		 * ログインユーザーの権限がidsに含まれているか確認する
		 * @param ids ユーザー権限IDs
		 * @returns 権限に含まれているか
		 */
		mixin_role_includes(ids: number[]): boolean {
			if (!this.$currentUser.role_id) {
				return false
			}
			return ids.includes(this.$currentUser.role_id)
		},
		/**
		 * ログインユーザーの権限がidsに含まれていないか確認する
		 * @param ids ユーザー権限IDs
		 * @returns 権限に含まれていないか
		 */
		mixin_role_unincludes(ids: number[]): boolean {
			if (!this.$currentUser.role_id) {
				return false
			}
			return !ids.includes(this.$currentUser.role_id)
		},
	},
})

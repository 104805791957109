import { RouteRecordRaw } from "vue-router"
import ContentsLayout from "@/layouts/ContentsLayout.vue"

export default [
	{
		path: "/admin",
		meta: {
			protected: true, // set TRUE if page needs authenticated user to access
		},
		component: () => import("../layouts/AdminLayout.vue"),
		children: [
			{
				path: "dashboard",
				name: "dashboard",
				component: () => import("../pages/admin/dashboard/index.vue"),
			},
			{
				path: "alert-and-threshold",
				component: () => import("../pages/admin/alert-threshold/Layout.vue"),
				children: [
					{
						path: "",
						name: "alert-and-threshold-index",
						component: () => import("../pages/admin/alert-threshold/Index.vue"),
					},
					{
						path: ":id?/view",
						name: "alert-and-threshold-view",
						component: () => import("../pages/admin/alert-threshold/View.vue"),
					},
					{
						path: ":id?/edit-station",
						name: "station-alert-form",
						component: () => import("../pages/admin/alert-threshold/Form.vue"),
					},
					{
						path: ":id?/edit-alert-notification",
						name: "alert-notification-form",
						component: () =>
							import(
								"../pages/admin/alert-threshold/AlertNotificationForm.vue"
							),
					},
					{
						path: ":id?/view/toilets/batch",
						name: "toilet-batch-form",
						component: () =>
							import("../pages/admin/alert-threshold/ToiletForm.vue"),
					},
					{
						path: ":id?/view/toilets/all",
						name: "toilet-all-form",
						component: () =>
							import("../pages/admin/alert-threshold/ToiletForm.vue"),
					},
				],
			},
			{
				path: "branch",
				name: "",
				component: ContentsLayout,

				children: [
					{
						path: "",
						name: "branch-index",
						component: () => import("../pages/admin/branch/Index.vue"),
					},
					{
						path: "form",
						name: "branch-form",
						component: () => import("../pages/admin/branch/Form.vue"),
					},
					{
						path: ":id/edit",
						name: "branch-edit",
						component: () => import("../pages/admin/branch/Form.vue"),
					},
				],
			},
			{
				path: "district",
				name: "",
				component: ContentsLayout,

				children: [
					{
						path: "",
						name: "district-index",
						component: () => import("../pages/admin/district/Index.vue"),
					},
					{
						path: "form",
						name: "district-form",
						component: () => import("../pages/admin/district/Form.vue"),
					},
					{
						path: ":id/edit",
						name: "district-edit",
						component: () => import("../pages/admin/district/Form.vue"),
					},
				],
			},
			{
				path: "line",
				name: "",
				component: ContentsLayout,

				children: [
					{
						path: "",
						name: "line-index",
						component: () => import("../pages/admin/line/Index.vue"),
					},
					{
						path: "form",
						name: "line-form",
						component: () => import("../pages/admin/line/Form.vue"),
					},
					{
						path: ":id/edit",
						name: "line-edit",
						component: () => import("../pages/admin/line/Form.vue"),
					},
				],
			},
			{
				path: "sales",
				name: "",
				component: ContentsLayout,

				children: [
					{
						path: "",
						name: "sales-index",
						component: () => import("../pages/admin/sales/Index.vue"),
					},
					{
						path: "form",
						name: "sales-form",
						component: () => import("../pages/admin/sales/Form.vue"),
					},
					{
						path: ":id/edit",
						name: "sales-edit",
						component: () => import("../pages/admin/sales/Form.vue"),
					},
				],
			},
			{
				path: "station",
				name: "",
				component: ContentsLayout,

				children: [
					{
						path: "",
						name: "station-index",
						component: () => import("../pages/admin/station/Index.vue"),
					},
					{
						path: "form",
						name: "station-form",
						component: () => import("../pages/admin/station/Form.vue"),
					},
					{
						path: ":id/edit",
						name: "station-edit",
						component: () => import("../pages/admin/station/Form.vue"),
					},
				],
			},
			{
				path: "toilets",
				name: "",
				component: ContentsLayout,
				children: [
					{
						path: "",
						name: "toilet-index",
						component: () => import("../pages/admin/toilet/Index.vue"),
					},
					{
						path: "form",
						name: "toilet-form",
						component: () => import("../pages/admin/toilet/Form.vue"),
					},
					{
						path: ":id/edit",
						name: "toilet-edit",
						component: () => import("../pages/admin/toilet/Form.vue"),
					},
				],
			},
			{
				path: "toilet-map",
				name: "",
				component: ContentsLayout,
				children: [
					{
						path: "",
						name: "toilet-map-index",
						component: () => import("../pages/admin/toilet-map/Index.vue"),
					},
					{
						path: "form",
						name: "toilet-map-form",
						component: () => import("../pages/admin/toilet-map/Form.vue"),
					},
					{
						path: ":id/form/:stationId",
						name: "toilet-edit-form",
						component: () => import("../pages/admin/toilet-map/Form.vue"),
					},
					{
						path: ":id/view",
						name: "toilet-map-view",
						component: () => import("../pages/admin/toilet-map/View.vue"),
					},
				],
			},
			{
				path: "users",
				name: "",
				component: ContentsLayout,

				children: [
					{
						path: "",
						name: "users-index",
						component: () => import("../pages/admin/users/Index.vue"),
					},
					{
						path: "form",
						name: "users-form",
						component: () => import("../pages/admin/users/Form.vue"),
					},
					{
						path: ":id/edit",
						name: "users-edit",
						component: () => import("../pages/admin/users/Form.vue"),
					},
				],
			},
			{
				path: "analysis",
				name: "",
				component: ContentsLayout,

				children: [
					{
						path: "",
						name: "analysis-index",
						component: () => import("../pages/admin/analysis/Index.vue"),
					},
				],
			},
			{
				path: "alertlog",
				name: "",
				component: ContentsLayout,
				children: [
					{
						path: "",
						name: "alertlog-index",
						component: () => import("../pages/admin/alertlog/Index.vue"),
					},
				],
			},
			{
				path: "adminlog",
				name: "",
				component: ContentsLayout,
				children: [
					{
						path: "",
						name: "adminlog-index",
						component: () => import("../pages/admin/adminlog/Index.vue"),
					},
				],
			},
		],
	},
] as Array<RouteRecordRaw>

import { defineStore } from "pinia"

// 通知ストアインターフェース
export interface NotificationStoreType {
	// 通知を表示するか
	display: boolean
	// 通知の種類
	type: "actionSuccess" | "deleteSuccess" | "error"
	// メッセージ表示
	message: string
	// メッセージの表示位置 (text-align)
	align: string
}

/**
 * 通知ストア
 */
export const NotificationStore = defineStore("notification", {
	state: () =>
		({
			display: false,
			type: "actionSuccess",
			message: "保存が完了しました",
			align: "",
		} as NotificationStoreType),
	actions: {
		// 通知表示を反転させる
		toggleDisplay() {
			this.display = !this.display
		},
	},
})

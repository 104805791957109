import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "l-page-header" }
const _hoisted_2 = { class: "l-page-subheader" }
const _hoisted_3 = { class: "page-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.displayTitle.title), 1),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.displayTitle.description), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_view)
    ])
  ]))
}
import { defineStore } from "pinia"

// ストアインターフェース
export interface IndexStoreType {
	// ローディングを行うか
	loading: boolean
}

/**
 * 通知ストア
 */
export const IndexStore = defineStore("index", {
	state: () =>
		({
			loading: false,
		} as IndexStoreType),
	actions: {
		startLoading() {
			this.loading = true
		},
		stopLoading() {
			this.loading = false
		},
	},
})
